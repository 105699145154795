

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

/* Judul Tiap Komponen */
.judul{
  text-align: center;
  letter-spacing: 2.5px;
  font-weight: 900;
  font-family: CircularStd-Black;
  color: #c1272d;
}

.center_align{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

@media screen and (max-width: 767px){
  /* Judul Fasilitas Responsive */
  .judul{
    font-size: 14px;
    width: 65vw;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

@media screen and (max-width: 992px) and (min-width:768px){
  /* Judul Fasilitas Responsive */
  .judul{
    font-size: 24px;
    width: 65vw;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

@font-face {
    font-family: 'Geomanist';
    src: local('Geomanist'), url('../Fonts/Geomanist-Regular.otf');
  }

  @font-face{
    font-family: 'CircularStd-Medium';
    src: local('CircularStd-Medium'), url('../Fonts/CircularStd-Medium.otf');
  }

  @font-face{
    font-family: 'CircularStd-Black';
    src: local('CircularStd-Black'), url('../Fonts/CircularStd-Black.otf');

  }