/* Footer Container */
.footer_container{
    padding: 30px;
}

/* Logo Pertamina Footer */
#logo_footer_container{
    align-items: center;
    display: flex;
    justify-content: center;
}
#logo_footer{
    width: 245px;
    height: 136px;
}

/* Deskripsi Footer */
#isi_footer_container{
    text-align: left;
    width: 450px;
    margin-left: auto;
    margin-right: auto;
    line-height: 0;
    padding: 20px;
    letter-spacing: 1px;
    font-size: 0.85rem;
}
#isi_footer_container p{
    font-family: Geomanist;
}
#isi_footer_container .judul_footer{
    font-family: CircularStd-Black;
}
#isi_footer_container_2{
    padding-top: 10px;
}

/* Mobile Web Style */
@media screen and (max-width: 440px){
    #isi_footer_container p{
        font-size: 6px;
    }
}
@media screen and (max-width: 767px){
    /* Footer Container */
    .col_footer_container{
        max-width: 80% !important; 
        margin-left: auto;
        margin-right: auto;
    }
    .footer_container{
        display: block !important;
        padding: 0;
    }

    /* Logo Footer */
    #logo_footer{
        width: 150px;
        height: 80px;       
    }
    #logo_footer_container{
        padding: 10px;
    }

    /* Description Footer */
    #isi_footer_container{
        font-size: 0.5rem;
        width: 100%;
        letter-spacing: 0.5px;
        padding: 0;
    }
    #isi_footer_container_1{
        float: left;
    }
    #isi_footer_container_2{
        padding:0;
        float: right;
    }
}