/* Penghargaan Container */
#page_penghargaan{
    background:  linear-gradient( to bottom,rgb(233, 233, 233), rgb(256,256,256) );
    padding-top: 8rem;
}
#page_penghargaan>div{
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
}


/* Penghargaan Images Container */
.penghargaan_image_container{
    text-align: center;
}
.pilihan_penghargaan {
    height: 600px;
    align-items: center;
    max-width: 80%;
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: space-between;
}


/* Penghargaan List Images */
.pilihan_penghargaan img, .description_penghargaan {
    width: 400px;
}


/* Penghargaan Images Description */
.description_penghargaan{
    margin-right: auto;
    margin-left: auto;
    padding-top: 15px;
}
.pilihan_penghargaan p{
    font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: 0.7px;
    text-align: left;
    color: #c1272d;
}


/* Mobile Web Style */
@media screen and (max-width: 400px){
    #page_penghargaan{
        padding-top: 3rem;
    }
    .pilihan_penghargaan{
        height: 160px;
    }
    #page_penghargaan>div{
        background-size: 60px;
    }

    /* Penghargaan Images */
    .pilihan_penghargaan img, .description_penghargaan {
        width: 120px;
    }

    /* Pilihan Penghargaan Description*/
    .pilihan_penghargaan p{
        font-size: 0.3rem;
    }
}
@media screen and (max-width: 767px) and (min-width: 401px){
    /* Pilihan Penghargaan Container */
    #page_penghargaan{
        padding-top: 5rem;
    }
    .pilihan_penghargaan{
        height: 240px;
    }
    #page_penghargaan>div{
        background-size: 120px;
    }


    /* Penghargaan Images */
    .pilihan_penghargaan img, .description_penghargaan {
        width: 30vw;
    }

    /* Pilihan Penghargaan Description*/
    .pilihan_penghargaan p{
        font-size: 0.5rem;
    }
}

/* Tablet Web Style */
@media screen and (max-width: 1280px) and (min-width:768px){
    /* Pilihan Penghargaan Container */
    #page_penghargaan{
        padding-top: 5rem;
    }
    .pilihan_penghargaan{
        height: 400px;
        padding-left: 2rem;
        padding-right: 2rem;
    }


    /* Penghargaan Images */
    .pilihan_penghargaan img, .description_penghargaan {
        width: 30vw;
    }

    /* Pilihan Penghargaan Description*/
    .pilihan_penghargaan p{
        font-size: 0.75rem;
    }
}