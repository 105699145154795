/* Container Fasilitas */
#page_fasilitas {
  background-color: #e9e9e9;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: bottom;
}
#fasilitas_list {
  width: 100%;
  text-align: center;
  height: 700px;
  display: flex;
}

/* Icon Arrow */
.icon_left,
.icon_right {
  width: 100px;
  z-index: 99;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #c1272d;
  cursor: pointer;
  opacity: 0.5;
  transition: 0.3s ease-in-out;
}
.icon_left {
  left: 0;
}
.icon_right {
  right: 0;
}
.icon_left:hover,
.icon_right:hover{
  opacity: 1;
  transition: 0.3s ease-in-out;
}


/* Carousel Items */
.pilihan_fasilitas {
  text-align: center;
  transition: 0.3s ease-in-out;
  border: none;
}
.pilihan_fasilitas img, .content {
  height: 450px;
  width: 350px; 
}
.pilihan_fasilitas img{
  box-shadow: 6px 10px 10px 0px rgba(0,0,0,0.2);
}


/* Description Fasilitas */
.judul_description {
  font-family: CircularStd-Black;
  font-size: 20px;
  font-weight: 600;
}
.pilihan_fasilitas p {
  font-family: Geomanist;
  font-size: 1.25em;
  font-weight: bold;
  color: #203164;
  margin: 0;
}
.content{
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
#isi_description {
  font-family: Geomanist;
  color: #772228;
}
#description_fasilitas {
  position: absolute;
  width: 80%;
  height: 75px;
  padding: 20px;
  text-align: left;
  bottom: 20px;
  font-size: 12px;
  background-image: linear-gradient(
    to right,
    #ffffff,
    rgba(255, 255, 255, 0.98) 14%,
    rgba(255, 255, 255, 0.88) 27%,
    rgba(255, 255, 255, 0.77) 39%,
    rgba(255, 255, 255, 0.66) 51%,
    rgba(255, 255, 255, 0.55) 63%,
    rgba(255, 255, 255, 0.44) 74%,
    rgba(255, 255, 255, 0.33) 86%,
    rgba(255, 255, 255, 0.22) 97%,
    rgba(255, 255, 255, 0.11)
  );
  box-shadow: gray;
}


/* Mobile Web Style */
@media screen and (max-width: 440px){
  /* Container Carousel Responsive */
  #page_fasilitas{
    background-size: 60px;
  }
  #fasilitas_list{
    height: 240px;
  }
  

  /* Icon Arrow Responsive */
  .icon_left,
  .icon_right {
    width: 7vw;
    height: 10vw;
    opacity: 1;
  }
  .icon_left{
    left: 10px;
  }
  .icon_right{
    right: 10px;
  }
  

  /* Pilihan Fasilitas Responsive */
  .pilihan_fasilitas img, .content {
    height: 150px;
    width: 110px;
  }
  #description_fasilitas {
    height: 30px;
    font-size: 6px;
    padding: 6px;
  }
}

@media screen and (max-width: 767px) and (min-width:441px){
  /* Container Carousel Responsive */
  #page_fasilitas{
    background-size: 60px;
  }
  #fasilitas_list{
    height: 320px;
  }
  

  /* Icon Arrow Responsive */
  .icon_left,
  .icon_right {
    width: 7vw;
    height: 10vw;
    opacity: 1;
  }
  .icon_left{
    left: 20px;
  }
  .icon_right{
    right: 20px;
  }
  

  /* Pilihan Fasilitas Responsive */
  .pilihan_fasilitas img, .content {
    height: 35vw;
    width: 25vw;
  }
  #description_fasilitas {
    font-size: 6px;
    padding: 6px;
    height: 30px;
    bottom: 25px;
  }  
}


/* Tablet Web Style */
@media screen and (max-width: 1280px) and (min-width:768px){
  #fasilitas_list{
    height: 600px;
  }
  #page_fasilitas{
    padding-top: 5rem;
  }
  

  /* Icon Arrow Responsive */
  .icon_left,
  .icon_right {
    width: 35px;
    height: 60px;
    opacity: 1;
  }
  .icon_left{
    left: 45px;
  }
  .icon_right{
    right: 45px;
  }
  
  /* Pilihan Fasilitas Responsive */
  .pilihan_fasilitas img, .content {
    width: 29vw;
    height: 39vw;
    box-shadow: 6px 10px 10px 0px rgba(0,0,0,0.2);
  }
  #description_fasilitas {
    height: 55px;
    font-size: 10px;
    padding: 12px;
    bottom: 40px;
  }  
}