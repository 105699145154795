
@media screen and (max-width: 1485px) and (min-width: 1319px){
    #main_page{
        width: 100%;
    }    
}

@media screen and (max-width: 1318px) and (min-width: 1119px){
    #main_page{
        width: 100%;
    }    
}


@media screen and (max-width: 462px) and (min-width: 360px){
    #main_page{
        width: 360px;
    }    
}



