/* Kontak Kami Container */
#page_kontak, .kontak_content{
    height: 820px;
}
#page_kontak{   
    margin-top:5rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    -moz-box-shadow:0 10px 8px rgba(182, 182, 182, 0.75);
     -webkit-box-shadow: 0 10px 8px rgba(182, 182, 182, 0.75);
     box-shadow: 0 10px 8px rgba(182, 182, 182, 0.75);
}
.kontak_content{
    background-image: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0.98) 14%, rgba(255, 255, 255, 0.94) 27%, rgba(255, 255, 255, 0.87) 39%, rgba(255, 255, 255, 0.77) 51%,
     rgba(255, 255, 255, 0.64) 63%, rgba(255, 255, 255, 0.47) 74%, rgba(255, 255, 255, 0.28) 86%, rgba(255, 255, 255, 0.07) 97%, rgba(255, 255, 255, 0));
     position: relative;
}

/* Form Container */
form{
    padding: 50px;
}
.nama_kontak, .nama_email{
    height: 100px;
}
.nama_textarea{
    height: 350px;
}

/* Form Input */
    /* Form Icon */
    .icon{
        width: 4rem;
        height: 3rem;
        position: absolute;
        top: 0px;
    }

    /* Form Input */
    .input_style, #text_area{
        border:1.5px solid #d4cfcd !important ;
        border-radius: 8.7px !important;
        outline: none;
        color: #203164 !important;
    }
    .input_style{        
        padding: 0.75rem 0px 0.75rem 60px !important;
        height: calc(1.5em + 0.75rem + 15px) !important;
    }
    #text_area{
        min-height: 300px;
        max-height: 300px;
        font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
        padding: 20px;       
    }
    ::placeholder{
        color: #d4cfcd;
    }

    /* Input Button */
    #input_button{
        padding: 0 20px;
        width: 150px;
        height: 50px;
        background-color: #C1272D;
        border-radius: 2rem;
        font-family: CircularStd-Black;
        font-size: 1.25em;
        outline: none;
        line-height: 1.2;
        transition: all 0.4s;
        position: relative;
        z-index: 1;
        font-weight: bold;
        border: none;
        text-align: center;
    }
    button{
        outline: none;
        border: none;
        background: transparent;
        box-shadow: none !important;
        cursor: pointer;   
    }   
    .validity_name{
        font-size: 0.75rem !important;
        font-weight: 500;
    }
    .invalid-feedback{
        background: white;
        padding: 5px 20px;
        opacity: 0.8;
        border-radius: 8.5px;
        text-align: left;
        height: 30px;
        align-items: center;
    }
    .is-invalid~.invalid-feedback{
        display: flex !important;
    }


/* Mobile Web Style */
@media screen and (max-width: 767px){
    /* Kontak Kami Container */
    #page_kontak{
        margin: 0;
    }

    /* Form Conatainer */
    form>.row{
        display: block !important;
    }
    .nama_kontak, .nama_email{
        height: 85px;
    }
    .nama_kontak, .nama_email, .nama_textarea{
        padding: 0px !important;
    }
    
    /* Form input */
        /* Form icon */
        .icon{
            width: 3.5rem;
            height: 2.5rem;
            top: 0px;
        }


        /* Form Input */
        .input_style{        
            padding: 0.75rem 0px 0.75rem 45px !important;
            height: calc(1.5em + 0.75rem + 7.5px) !important;
            font-size: 0.75rem !important;
        }
        #text_area{
            font-size: 0.75rem;
            padding: 20px;     
        }


        /* Button */
        .button_container {
            text-align: center;
        }
        #input_button{
            width: 100px;
            height: 50px;
            font-size: 1rem;
        }


        /* Error Message */
        .invalid-feedback{
            padding: 5px 10px 5px 20px;
            height: 30px;
        }
        .is-invalid~.invalid-feedback{
            display: flex !important;
        }
        .validity_name{
            font-size: 10px !important;
        }
}


/* Table Web Style */
@media screen and (max-width: 1280px) and (min-width:768px){
    /* Kontak Kami Container */
    #page_kontak{
        margin: 0;
    }

    /* Form Conatainer */
    form{
        width: 100vw;
    }
    
}