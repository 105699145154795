/* Navbar Container */
#navlink{
    padding: 0;
}
#responsive-navbar-nav{
    color: white;
}
.navbar{
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

/* Navbar Items */
.activeNav{
    background-color: #C1272D !important;
}
#navbar_button{
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: large;
    align-items: center;
    border: none;
    font-family: CircularStd-Medium;
    background-color: transparent;
    width: 12rem;
    padding-bottom: 1rem;
    padding-top: 5rem;
    box-shadow: none;
}
#navbar_button:hover{
    color: white;
}
#navlink{
    background-color: white;
    margin-right: 5px;
}

#navlink:hover{
    background-color: #C1272D;
}


/* Navbar Logo */
.navbar_logo_container{
    width: 25%;
    text-align: center;
}
.logo_nav{
    width: 70px;
    height: 70px;
    background-color: transparent;
    display: inline-block;
}


/* Mobile Web Style*/
@media screen and (max-width: 767px){
    /* Navbar Container */
    .navbar{
        padding : .5rem 1rem !important;
    }

    /* Navbar Logo */
    .logo_nav{
        width: 40px;
        height: 45px;
    }

    /* Navbar Items */
    #navbar_button{
        align-items: flex-start;
        text-align: left;
        width: 12vw;
        padding-top: 3rem;
        font-size: 14px;
    }
    #navlink{
        width: 50vw;
        padding-right: 0;
    }
    .navbar-light .navbar-toggler{
        color: red !;
        border-color: transparent !important;
        font-size: x-large !important;
    }
    .navbar-collapse{
        position: initial;
        float: right;
        right: 60rem;
        top: 0rem;
        height: 21%
    }
    #responsive-navbar-nav{
        margin-left: 45vw;
        margin-bottom: -25rem;
        background-color: white;
        z-index: 99;
        margin-right: -2rem;
    }
}


/* Tablet Web Style */
@media screen and (max-width: 991px) and (min-width: 768px){
    /* Navbar Container */
    .navbar{
        padding : .5rem 1rem !important;
    }

    /* Navbar Items */
    #navbar_button{
        padding: 0;
    }
    #navlink{
        height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@media screen and (max-width: 1280px) and (min-width: 992px){
    /* Navbar Container */
    .navbar{
        padding : .5rem 1rem !important;
    }

    /* Navbar Items */
    #navbar_button{
        width: 10rem;
        font-size: medium;
    }
}

