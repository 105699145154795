/* Container Visi dan Misi */
#page_profile {
  background-color: #e9e9e9;
  position: relative;
  height: 700px;
  background-repeat: no-repeat;
}
#pattern1 {
  opacity: 0;
}
.sub-container {
  padding-left: 3rem;
  padding-right: 3rem;
}

/* Description Visi dan Misi */
#judul_profile,
h5 {
  font-family: CircularStd-Black;
}
#row_profile {
  margin-top: 40px;
}
#judul_profile {
  color: #c1272d;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 2.5px;
}
#judul_profile > h1 {
  font-size: 4.5vw;
}
.description_container {
  font-family: Geomanist;
  letter-spacing: 1px;
  line-height: 1.2;
  font-size: 1rem;
}


/* Mobile Web Style */
@media screen and (max-width: 440px) {
  /* Container Visi dan Misi */
  .sub-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  /* Description Visi dan Misi */
  #judul_profile > h1 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 767px) {
  /* Background Container */
  #pattern1 {
    width: 35vw;
  }

  /* Description Visi dan Misi */
  .description_container {
    font-size: 0.75rem;
  }
}
@media screen and (max-width: 992px) and (min-width: 768) {
  /* Background Container */
  #pattern1 {
    width: 35vw;
  }
}


/* Tablet Web Style */
@media screen and (max-width: 1280px) {
  /* Background Container */
  #page_profile {
    background-image: none !important;
  }
  #pattern1 {
    opacity: 1;
    transform: scaleX(-1);
    right: 0;
    position: absolute;
  }
}


/* Desktop Web Style */
@media screen and (min-width: 1281px) {
    .description_container {
        font-size: 1.25rem;
      }
}