.page_beranda{
    width: 100%;
    height: 600px;
    display: flex;
    align-items: flex-end;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.judul_beranda{
    color: #C1272D;
    font-family: CircularStd-Black;
    font-size: 41.8px;
    margin-left: 9rem;
    font-weight: 900;
}

.isi_beranda{
    color: #666666;
    font-family: Geomanist;
    padding-left: 1.5rem;
    margin-left: 9rem;
    border-left: 0.3rem solid #C1272D;
}
.isi_beranda>h1{
    font-size: 24px !important;
}



#img1{
    z-index: -99;
    position: absolute;
    width: 100%;
    height: 46rem;
    top: 8rem;
}

.component_content{
    background-image: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0.98) 14%, rgba(255, 255, 255, 0.94) 27%, rgba(255, 255, 255, 0.87) 39%, rgba(255, 255, 255, 0.77) 51%,
     rgba(255, 255, 255, 0.64) 63%, rgba(255, 255, 255, 0.47) 77%, rgba(255, 255, 255, 0.28) 90%, rgba(255, 255, 255, 0));
    padding-left: 5rem;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    width: 95%;
    padding-top: 1rem;
    

}





@media screen and (max-width: 767px){
    .page_beranda{
        margin-top: 3rem;
        width: 100%;
        height: 22rem;
        display: flex;
        align-items: flex-end;
        background-attachment: unset;
    }

    #img1{
        z-index: -99;
        position: absolute;
        width: 100%;
        height: 25rem;
    
    }

    .judul_beranda{
        color: #C1272D;
        font-family: CircularStd-Black;
        font-size: 20px;
        margin-left: 0.5rem;
        font-weight: 900;
    }

    .isi_beranda{
        color: #666666;
        font-family: Geomanist;
        padding-left: 1rem;
        margin-left: 0.5rem;
        border-left: 0.3rem solid #C1272D;    
    }
    .isi_beranda>h1{
        font-size: small !important;
        margin-bottom: 0 !important;
    }
    .component_content{
        background-image: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0.80) 24%, rgba(255, 255, 255, 0.76) 37%, rgba(255, 255, 255, 0.69) 48%, rgba(255, 255, 255, 0.59) 60%,
         rgba(255, 255, 255, 0.46) 72%);
        padding-left: 1.5rem;
        padding-bottom: 0.8rem;
        width: 100vw;
        padding-top: 0.5rem;
        margin-bottom: 0;
    }

    @media screen and (max-width: 992px) and (min-width: 768px){
        
#page_beranda{
    margin-top: 0.5rem;
    width: 60rem;
    height: 50rem;
    display: flex;
    align-items: flex-end;
    background-size: cover;
}


    }
}